"use client";

import React from "react";
import { usePathname } from "next/navigation";
import { ThemeOptions, ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQueryState from "@/hooks/useMediaQueryState";
import useHeaderNavHeight from "@/hooks/useHeaderNavHeight";
import { Theme, useTheme } from "@mui/material";
import { genericThemeWithBreakpoints } from "@/elements/src";

import AstrosLandingPage from "./_components/LandingPage";
import ThemedBox from "./_components/ThemedBox";
import Navbar from "./_components/Navbar";
import Footer from "./_components/Footer";
import {
  cloudinaryAssets,
  showPlaceholderPage,
  aaThemeObject,
} from "./constants";

const astrosThemeObject: ThemeOptions = {
  ...genericThemeWithBreakpoints,
  ...aaThemeObject,
};

const astrosTheme: Theme = createTheme(astrosThemeObject);

const AwkwardAstronauts = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const theme = useTheme();
  const isMobile = useMediaQueryState(theme.breakpoints.down("md"));
  const { navHeight: headerHeight } = useHeaderNavHeight()

  const spaceMissionIsShowingGrid = isMobile
    ? ""
    : `url(${cloudinaryAssets.spaceMissionsBGGrid})`;

  const isExplorePage = pathname === "/awkward-astronauts/explore";
  const isSpaceMissionsPage = pathname === "/awkward-astronauts/space-missions";
  const genSpaceMissionPadding = isSpaceMissionsPage ? 0 : "64px";

  return (
    <ThemeProvider theme={astrosTheme}>
      {!showPlaceholderPage && (
        <Navbar isMobile={!!isMobile} section={pathname} />
      )}
      <ThemedBox
        minHeight={`calc(100vh - ${+headerHeight + 62}px)`}
        padding={
          isExplorePage
            ? `${genSpaceMissionPadding} 0`
            : `${genSpaceMissionPadding} 0 ${
                isMobile ? "82.75px" : "120px"
              }`
        }
        sx={{
          backgroundImage: isSpaceMissionsPage
            ? spaceMissionIsShowingGrid
            : `url(${cloudinaryAssets.stars})`,
          backgroundPosition: "bottom",
          backgroundSize: isSpaceMissionsPage ? "150%" : "cover",
          backgroundRepeat: "repeat",
        }}
      >
        {showPlaceholderPage ? (
          <AstrosLandingPage
            headerHeight={+headerHeight}
            isMobile={!!isMobile}
          />
        ) : (
          children
        )}
      </ThemedBox>
      {!isExplorePage && <Footer section={pathname} isMobile={!!isMobile} />}
    </ThemeProvider>
  );
};

export default AwkwardAstronauts;
