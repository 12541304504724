import React from "react";
import Link from "next/link";
import { Box, Typography, styled } from "@mui/material";

import {
  AND,
  ART_BY,
  AWKWARD_ASTRONAUTS_BY,
  GEMINI,
  LAMBO_NAME,
  NIFTY_GATEWAY,
  PREPARING_LIFTOFF,
  cloudinaryAssets,
} from "../constants";

interface StyledContainerProps {
  headerHeight: number;
  isMobile: boolean;
}

const ThemedBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  "& a": {
    color: "inherit",
  },
}));

const AstrosLandingPage = ({
  headerHeight,
  isMobile,
}: StyledContainerProps) => {
  return (
    <ThemedBox
      sx={{
        backgroundImage: `url(${cloudinaryAssets.stars})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        fontSize: "24px",
        alignItems: "center",
        display: "flex",
        height: `calc(100vh - ${headerHeight}px)`,
        maxWidth: "100vw",
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: isMobile ? "40px" : "56px" }}>
            🚀
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontSize: isMobile ? "20px" : "48px",
              textAlign: "center",
            }}
          >
            {PREPARING_LIFTOFF}
          </Typography>
        </Box>

        <Box
          sx={{
            margin: isMobile ? "120px auto" : "120px auto 40px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <picture>
            <img
              src={cloudinaryAssets.logoSprites}
              alt="Logos for Awkward astronauts, Gemini, and Nifty Gateway"
              width="200px"
            />
            &nbsp;
          </picture>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: 600,
              mt: "24px",
              textAlign: "center",
            }}
          >
            {AWKWARD_ASTRONAUTS_BY}&nbsp;
            <a
              href="https://www.gemini.com/awkward-astronauts"
              target="_blank"
              rel="noreferrer"
            >
              {GEMINI}
            </a>
            &nbsp;{AND}&nbsp;
            <Link href="/marketplace/collectible/0xc71561e12faf378b07eacd36b3d0eb0d13a5fb1c">
              {NIFTY_GATEWAY}
            </Link>
            {ART_BY}&nbsp;
            <Link href="/le_lambo">{LAMBO_NAME}</Link>
          </Typography>
        </Box>
      </Box>
    </ThemedBox>
  );
};

export default AstrosLandingPage;
