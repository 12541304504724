import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "@/elements/src";

import ThemedBox from "./ThemedBox";
import {
  cloudinaryAssets,
  ASTROS_URLS,
  OWNERSHIP_TERMS_OF_LICENSE,
} from "../constants";
import Image from "next/image";

interface FooterProps {
  isMobile: boolean;
  section: string;
}

const Footer = ({ isMobile, section }: FooterProps) => {
  const isSpaceMissionsPage = section === "/awkward-astronauts/space-missions";
  const spaceMissionIsShowingGrid = isMobile
    ? ""
    : `url(${cloudinaryAssets.spaceMissionsBGGrid})`;
  return (
    <ThemedBox
      sx={{
        backgroundImage: isSpaceMissionsPage
          ? spaceMissionIsShowingGrid
          : `url(${cloudinaryAssets.stars})`,
        backgroundPosition: "top",
        backgroundSize: isSpaceMissionsPage ? "150%" : "cover",
        paddingBottom: "64px",
      }}
    >
      <Container maxWidth="xl">
        <Box
          display={isMobile ? "block" : "flex"}
          justifyContent={isMobile ? "center" : "space-between"}
          textAlign={isMobile ? "center" : "inherit"}
        >
          <Typography
            sx={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "1rem" }}
          >{`© ${new Date().getFullYear()} Awkward Astronauts`}</Typography>
          <Box
            sx={{
              fontSize: "16px",
              "& a": {
                fontSize: "inherit",
                textDecoration: "underline",
              },
              display: isMobile ? "block" : "flex",
              gap: isMobile ? "0" : "24px",
            }}
          >
            <Link href={ASTROS_URLS.TERMS} style={{ color: "white" }}>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: isMobile ? "1.2rem" : 0,
                  textDecoration: "underline",
                  fontSize: isMobile ? 12 : 16,
                  color: "primary",
                }}
              >
                {OWNERSHIP_TERMS_OF_LICENSE}
              </Typography>
            </Link>
            <Box
              sx={{
                width: isMobile ? "100%" : "inherit",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: isMobile ? "4.75rem" : "4.25rem",
                  justifyContent: "space-between",
                }}
              >
                <a href={ASTROS_URLS.DISCORD} target="_blank" rel="noreferrer">
                  <Image
                    width={24}
                    height={24}
                    src={"/socials/discord-white.png"}
                    alt="discord"
                  />
                </a>
                <a href={ASTROS_URLS.TWITTER} target="_blank" rel="noreferrer">
                  <Image
                    width={24}
                    height={24}
                    src={"/socials/twitter-white.png"}
                    alt="twitter"
                  />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemedBox>
  );
};

export default Footer;
