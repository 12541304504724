import React from "react";

import { Box, Container, Typography } from "@mui/material";
import { Link } from "@/elements/src";
import { URL } from "@/app/constants";

import ThemedBox from "./ThemedBox";
import {
  EXPLORE,
  SPACE_MISSIONS,
  SPACE_STATION,
  cloudinaryAssets,
} from "../constants";

interface NavbarProps {
  isMobile: boolean;
  section: string;
}

const Navbar = ({ isMobile, section }: NavbarProps) => {
  const isExplorePage = section === "/awkward-astronauts/explore";
  const exploreMobileNavStyles = isExplorePage
    ? { justifyContent: "flex-end" }
    : {
        position: "absolute",
        right: "0",
        justifyContent: "flex-end",
        width: "400px",
      };
  return (
    <ThemedBox
      position={isMobile ? "initial": "sticky"}
      width="100%"
      top={0}
      zIndex={9}
    >
      <Container
        maxWidth="xl"
        sx={{
          alignItems: "center",
          display: "flex",
          height: "64px",
          gap: 3,
        }}
      >
        <Link href={URL.AWKWARD_ASTRONAUTS_HOME}>
          <picture>
            <img width="40px" src={cloudinaryAssets.aaLogo} alt="AA" />{" "}
          </picture>
        </Link>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            marginLeft: "auto",
            position: "relative",
            gap: 3,
          }}
        >
          <Box
            sx={
              isMobile
                ? { ...exploreMobileNavStyles, display: "flex", gap: 2 }
                : { alignItems: "center", display: "flex", gap: 2 }
            }
          >
            <Link href={URL.SPACESTATION} underline={false}>
              <Typography
                variant="button"
                sx={{
                  textTransform: "uppercase",
                  color: "white",
                  fontSize: isMobile ? "10px" : "16px",
                  fontWeight: "700",
                }}
              >
                {SPACE_STATION}
              </Typography>
            </Link>
            <Link href={URL.SPACEMISSIONS} underline={false}>
              <Typography
                variant="button"
                sx={{
                  textTransform: "uppercase",
                  color: "white",
                  fontSize: isMobile ? "10px" : "16px",
                  lineHeight: isMobile ? "12px" : "20px",
                  fontWeight: "700",
                }}
              >
                {SPACE_MISSIONS}
              </Typography>
            </Link>
            <Link href={URL.EXPLORE} underline={false}>
              <Typography
                variant="button"
                sx={{
                  textTransform: "uppercase",
                  color: "white",
                  fontSize: isMobile ? "10px" : "16px",
                  lineHeight: isMobile ? "12px" : "20px",
                  fontWeight: "700",
                }}
              >
                {EXPLORE}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemedBox>
  );
};

export default Navbar;
